<template>
  <div>
    <div class="head-for-bg mobile-for-head" :style="{ backgroundImage: `url(${$store.getters.backgroundImage})` }">
      <img :src="$store.getters.backgroundImage" class="hide-mobile" alt="" />
      <div class="for-title pl-res-10 pr-res-10 top-30">
        <span>Galeri</span>
        <p class="text-center">
          Galeri Badan Penyelenggara Jaminan Produk Halal
        </p>
      </div>
    </div>
    <div class="container">
      <div class="_sub-container pad-20">
        <div class="text-left">
          <div class="row">
            <div class="last-section  for-last-responsive container-for-content    pad-20 content-galery-detail">
              <ul>
                <li v-for="(gallery, index) in galleries" :key="index" @click="showedGallery = index">
                  <template v-if="gallery.thumb !== undefined">
                         <img class="for-video-thumb" src="@/assets/images/compro/icon/play-btn.svg" alt="">
                    <img :src="gallery.thumb" alt="" />
                  </template>
                  <template v-else>
                    <img :src="gallery.src" alt="" /> 
                  </template>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="mt-3 pb-3 no-last style_me"> 
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="center"></b-pagination>
        </div>
      </div>
    </div>
    <CoolLightBox 
      :items="galleries" 
      :index="showedGallery"
      @close="showedGallery = null">
    </CoolLightBox>
  </div>
</template>
<script>
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

export default {
  data() {
    return {
      galleries: [],
      showedGallery: null,
      rows: 0,
      perPage: 20,
      currentPage: 1,
    }
  },
  components: { CoolLightBox },
  watch: {
    currentPage() {
      this.getGallery()
    }
  },
  mounted() {
    this.getGallery()
  },
  methods: {
    getGallery() {
      this.axios.get(`gallery`, {
        params: {
          page: this.currentPage,
          limit: this.perPage,
          sortBy: 'created_at',
          sortType: 'DESC',
        }
      }).then( response => {
        this.rows = response.data.result.total
        this.galleries = response.data.result.galleries.map( item => {
          const gallery = {
            title: item.title,
            src: item.file,
          }
          if (item.type == 'video') {
            gallery.thumb = item.video_thumbnail
          } 

          return gallery
        })
      })
    }
  }
};
</script>